import React from 'react';
import Logo from '../logo/logo';
import { Link } from 'react-router-dom';
import { ReactComponent as InstagramLogo } from '../../assets/icons/instagram-brands.svg';
import { ReactComponent as LinkedinLogo } from '../../assets/icons/linkedin-brands.svg';
import './footer.scss';

const Footer = () => {
    return (
        <footer className="app__footer">
            <Logo inverted />
            <a
                className="app__footer__social-icon"
                href="https://www.instagram.com/anetteshovland/"
                rel="nofollow noopener noreferrer"
                target="_blank"
                aria-label="Open my Instagram profile in a new tab">
                <InstagramLogo alt="" />
            </a>
            <a
                className="app__footer__social-icon"
                href="https://no.linkedin.com/in/anette-storm-hovland-78469b26"
                rel="nofollow noopener noreferrer"
                target="_blank"
                aria-label="Open my Linkedin profile in a new tab">
                <LinkedinLogo alt="" />
            </a>
            <nav className="app__footer__policies-list">
                <Link
                    className="app__footer__policies-list__link"
                    to="/privacy-policy">
                    Privacy policy
                </Link>
                <Link
                    className="app__footer__policies-list__link"
                    to="/cookies">
                    Cookies
                </Link>
            </nav>
        </footer>
    );
};

export default Footer;
