import React from 'react';
import { ReactComponent as InstagramLogo } from './assets/icons/instagram-brands.svg';
//import { ReactComponent as LinkedinLogo } from './assets/icons/linkedin-brands.svg';

export const Menu = [
    {
        label: 'Prosjekter',
        path: '/prosjekter',
        external: false
    },
    {
        label: 'Om meg',
        path: '/om-meg',
        external: false
    },
    {
        label: 'Kontakt',
        path: '/kontakt',
        external: false
    },
    {
        label: 'Instagram',
        path: 'https://www.instagram.com/anetteshovland/',
        icon: <InstagramLogo />,
        external: false
    }
    /*{
      label: 'Linkedin',
      path: 'https://www.linkedin.com/in/steinarhovland/',
      icon: <LinkedinLogo />,
      external: false
  }*/
];
