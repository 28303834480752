import React from 'react';
import './logo.scss';

const Logo = ({ inverted }) => {
    return (
        <span className={`shx-logo ${inverted ? 'shx-logo--inverted' : ''}`}>
            Anette <span className="shx-logo--bold">Hovland</span>
        </span>
    );
};

export default Logo;
